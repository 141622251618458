import React from "react";
import resumeFile from "../documents/kimteng-LEY-CV.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2013 - 2017",
      title: "High School",
      place: "PREAH SIHANOUK HIGHSCHOOL",
      desc: "Graduated High School with B Grade on BAC-II Exam.",
    },
    {
      yearRange: "2017 - 2021",
      title: "Bachelor Degree",
      place: "KIRIROM INSTITUTE OF TECHNOLOGY",
      desc: "Pursuing Bachelor of Software Engineering.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - 2023",
      title: "Nuwin Mobile APP by opinion system",
      place: "Peppermint Cambodia",
      desc: "Full Stack Engineer under Peppermint Cambodia",
    },
    {
      yearRange: "2022",
      title: "ERP internal system",
      place: "Peppermint Cambodia",
      desc: "Frontend Developer under Peppermint Cambodia",
    },
    {
      yearRange: "2022",
      title: "Accounting System from Comiteo",
      place: "Peppermint Cambodia",
      desc: "Frontend Developer under Peppermint Cambodia",
    },
    {
      yearRange: "2022",
      title: "Achievement JP",
      place: "Kirirom Institute of Technology",
      desc: "Full Stack Developer under Kirirom Institute of Technology",
    },
    {
      yearRange: "2021 - 2022",
      title: "Odoo System",
      place: "ERP Cambodia",
      desc: "Full Stack Developer and ERP Consultant",
    },
    {
      yearRange: "2020 - 2021",
      title: "KIT Japanese CLIENT",
      place: "Kirirom Institute of Technology",
      desc: "Remote Support Developer outsourcing to 2 Japanese company",
    },
  ];

  const skills = [
    {
      name: "PYTHON/PHP",
      percent: 85,
    },
    {
      name: "ODOO",
      percent: 95,
    },
    {
      name: "HTML/CSS/JavaScript",
      percent: 90,
    },
    {
      name: "REACT/REACT Native",
      percent: 90,
    },
    {
      name: "VUE/VUE3",
      percent: 80,
    },
    {
      name: "JQUERY/BACKBONE.js/OWL",
      percent: 80,
    },
    {
      name: "TAILWIND/BOOTSTRAP",
      percent: 85,
    },
    {
      name: "GIT, Web Server, Network, Hypervisor",
      percent: 85,
    },
    {
      name: "Docker, Cloud Hosting (GCP, Digital Ocean, Cloudflare)",
      percent: 80,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="col gx-5">
          {/* My Education */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            <div className="row">
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div className="col-sm-6 pr-4">
                  <div
                    key={index}
                    className={
                      "bg-white rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>

            <div className="row">
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div className="col-sm-6 pr-4">
                  <div
                    key={index}
                    className={
                      "bg-white  rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                      {value.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
